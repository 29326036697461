<template>
  <div v-if="items.length > 0">
    <v-alert
      :prominent="!$vuetify.breakpoint.xs"
      class="ma-0 rounded-t-0"
      type="info"
      text
      v-if="settings.studentTitleNow"
    >
      Du kannst den Titel deiner Arbeit bis am
      <DateValue :value="settings.studentTitleEndDate" long /> anpassen
    </v-alert>
    <v-card flat class="rounded-t-0" :loading="loading">
      <v-list v-for="item in items" :key="'ma' + item.id">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle> meine Arbeit </v-list-item-subtitle>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <PersonItem :value="item.firstCoach" />
            </div>
          </template>
          <span>Betreuung</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <PersonItem :value="item.secondCoach" />
            </div>
          </template>
          <span>Betreuung</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <PersonItem :value="item.assessor" />
            </div>
          </template>
          <span>Zweitbeurteilung</span>
        </v-tooltip>
      </v-list>
      <v-divider />
      <v-card-actions>
        <v-btn text block small :to="{ name: 'Thesis' }"
          >zur Maturaarbeit</v-btn
        ></v-card-actions
      >
    </v-card>
  </div>
</template>
<script>
import DateValue from "common/components/DateValue.vue";
import PersonItem from "@/components/PersonItem.vue";
export default {
  components: { DateValue, PersonItem },
  data() {
    return {
      loading: false,
      items: [],
      settings: {},
    };
  },
  computed: {},
  methods: {
    async fetchData() {
      this.loading = true;
      this.settings = await this.apiList({
        resource: "thesis/settings",
      });
      this.items = await this.apiList({
        resource: "thesis/thesis",
        query: "student=" + this.$_profilePerson.id,
      });

      this.loading = false;
      if (this.items.length > 0) {
        this.$emit("loaded");
      }
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>
